import { navigate } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';

import { selectUserData } from '../redux/user/user.selectors';
import { addSingleProduct } from '../redux/shop/cart/cart.actions';
import { IPointsBenefitLabel } from '../models/product-promotion.model';
import {
    addLoginModalAction,
    addPayByPointsModalAction,
} from '../redux/shop/order/pay-by-points.actions';
import useProductDiscounts from './use-product-discounts';

interface IAddToCartPayload {
    productId: number;
    variantId?: number;
}

interface IAddToCartMeta {
    submitAfter?: boolean;
    validateAfter?: boolean;
    payByPointsData?: {
        labelPointsBenefit: IPointsBenefitLabel;
    };
    redirectToCart?: boolean;
}

type TAddToCartAction = (payload: IAddToCartPayload, meta?: IAddToCartMeta) => void;

export default function useCustomAddToCart(productId: number) {
    const isUserLogged = useSelector(selectUserData) !== null;
    const { labelPointsBenefit } = useProductDiscounts({ productId });
    const dispatch = useDispatch();

    const dispatchAddToCart: TAddToCartAction = (payload, meta) => {
        dispatch(addSingleProduct(payload, meta));
    };

    const addToCartWithPointsChecking: TAddToCartAction = (payload, meta) => {
        if (!labelPointsBenefit || !labelPointsBenefit.pointsPercent) {
            dispatchAddToCart(payload, meta);
            return;
        }

        if (!isUserLogged) {
            dispatch(
                addLoginModalAction({
                    onCancel: () => navigate('/login'),
                    onConfirm: () => dispatchAddToCart(payload, meta),
                })
            );
            return;
        }

        dispatch(
            addPayByPointsModalAction({
                pointsPercent: labelPointsBenefit.pointsPercent,
                onCancel: () => dispatchAddToCart(payload, meta),
                onConfirm: () => {
                    const extendedMeta = {
                        ...meta,
                        payByPointsData: {
                            labelPointsBenefit,
                            productId,
                            promotionId: labelPointsBenefit.promotionId,
                        },
                    };
                    dispatchAddToCart(payload, extendedMeta);
                },
            })
        );
    };

    const addToCart: TAddToCartAction = (payload, meta) => {
        addToCartWithPointsChecking(payload, meta);
    };

    return {
        addToCart,
    };
}
