import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    img,
    title,
    cover,
    contentHolder,
    imgHolder,
    text,
    productLoader,
    productCardWrapper,
    seeMoreButton,
    pointsDiscountLabel,
} from './product-card.module.scss';
import { config } from '../../../config';
import { addModalAction } from '../../../redux/actions/actions-modals';
import useMedia from '../../../hooks/use-media';
import useProductDiscounts from '../../../hooks/use-product-discounts';
import isWindow from '../../../utills/isWindow';
import { selectCartIsLoading } from '../../../redux/shop/cart/cart.selectors';
import useCustomAddToCart from '../../../hooks/use-custom-add-to-cart';

import CartButton from '../../atoms/cart-button';
import Loader from '../../atoms/loader';
import LinkButton from '../../atoms/link-button';
import DiscountBenefitList from '../discount-benefit-list';
import DiscountConditionList from '../discount-condition-list';
import Price from '../../atoms/price';
import Link from '../../atoms/link';
import PointsDiscountLabel from '../../atoms/points-discount-label';

export const ProductCard = ({ item, itemProps = {}, path }) => {
    const {
        name,
        shortDescription,
        price,
        slug,
        media,
        productId,
        canBuy,
        remoteShopUrl,
        variants,
    } = item;
    const { externalLinks = false, onCartAdded, context = 'shop' } = itemProps;
    const cartLoading = useSelector(selectCartIsLoading);
    const dispatch = useDispatch();
    const [imageUrl, imageAlt] = useMedia({
        media,
        relation: config.mediaRelationsMap.listCover,
    });
    const discount = useProductDiscounts({ productId });
    const { addToCart } = useCustomAddToCart(productId);

    const handleAddToCart = () => {
        if (!canBuy) {
            dispatch(
                addModalAction({
                    title: 'Produkt chwilowo niedostępny w sprzedaży',
                })
            );
            return;
        }

        const payload = {
            productId: productId,
        };

        addToCart(
            payload,
            context === 'order' ? { validateAfter: true } : { redirectToCart: true }
        );

        if (onCartAdded) {
            onCartAdded(productId);
        }
    };

    return (
        <div className={productCardWrapper}>
            {isWindow() && discount.isLoading && discount.isEnabled && (
                <Loader fullContainer className={productLoader} />
            )}
            {imageUrl && (
                <Link className={imgHolder} to={`${path}/${slug}/`} external={externalLinks}>
                    {!discount.isLoading && variants?.length === 0 && (
                        <DiscountBenefitList
                            benefits={discount.benefitLabels}
                            color={discount.color}
                        />
                    )}
                    <img className={`${img} ${cover}`} alt={imageAlt} src={imageUrl} />
                    <PointsDiscountLabel
                        className={pointsDiscountLabel}
                        labelPointsBenefit={discount.labelPointsBenefit}
                    />
                </Link>
            )}
            <div className={contentHolder}>
                <Link to={`${path}/${slug}/`} external={externalLinks}>
                    <h3 className={title}>{name}</h3>
                </Link>
                <p className={text}>{shortDescription}</p>

                <Price
                    defaultPrice={price}
                    price={discount.price}
                    basePrice={discount.basePrice}
                    isLoading={discount.isLoading}
                    noLoader
                    isDiscounted={variants?.length === 0 && discount?.isDiscounted}
                    color={discount.color}
                />

                {!discount.isLoading && (
                    <DiscountConditionList
                        conditions={variants?.length === 0 ? discount?.conditionLabels : []}
                        color={discount.color}
                    />
                )}

                {remoteShopUrl || variants.length > 0 ? (
                    <LinkButton
                        className={seeMoreButton}
                        to={remoteShopUrl || `${path}/${slug}/`}
                        external={remoteShopUrl || externalLinks}
                        color="yellow"
                    >
                        {remoteShopUrl && 'Zobacz więcej'}
                        {!remoteShopUrl && 'Zobacz i wybierz opcję dla siebie'}
                    </LinkButton>
                ) : (
                    <CartButton
                        onClick={handleAddToCart}
                        disabled={cartLoading}
                        className={'handleAddToCart'}
                        disabledStyle={!canBuy}
                    />
                )}
            </div>
        </div>
    );
};

export default ProductCard;
