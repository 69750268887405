import React from 'react';

import { container, priceLoader, originalPrice } from './price.module.scss';
import { DISCOUNTS_ENABLE } from '../../hooks/use-product-discounts';
import { IPrice } from '../../models/price.model';
import isWindow from '../../utills/isWindow';
import getDiscountInfo from '../../utills/get-discount-info';

import Loader from './loader';

export interface IPriceProps {
    price?: IPrice;
    basePrice?: IPrice;
    isDiscounted?: boolean;
    defaultPrice?: IPrice;
    className?: string;
    originalPriceClassName?: string;
    isLoading?: boolean;
    noLoader?: boolean;
    color?: ReturnType<typeof getDiscountInfo>['color'];
}

export default function Price({
    price,
    basePrice,
    isDiscounted,
    defaultPrice,
    className = '',
    originalPriceClassName = '',
    isLoading,
    noLoader,
    color,
}: IPriceProps) {
    const shouldDisplayDefaultPrice =
        !isWindow() || (isWindow() && !DISCOUNTS_ENABLE) || isLoading || !price;
    const shouldDisplayLoading = !noLoader && isLoading;

    let originalPriceStyles;

    if (color) {
        originalPriceStyles = {
            color: `var(${color}`,
        };
    }

    if (!defaultPrice) {
        return null;
    }

    return (
        <div className={`${container} ${className}`}>
            {shouldDisplayDefaultPrice ? defaultPrice.grossDisplay : price?.grossDisplay}
            {!isLoading && isDiscounted && basePrice && (
                <span
                    className={`${originalPrice} ${originalPriceClassName}`}
                    style={originalPriceStyles}
                >
                    {basePrice.grossDisplay}
                </span>
            )}
            {shouldDisplayLoading && <Loader fullContainer innerElementClassName={priceLoader} />}
        </div>
    );
}
