export default function mergeCategories(categories, options) {
    const { totalItemCount, itemCountName, firstCategoryName } = options;
    return [
        {
            name: firstCategoryName ? firstCategoryName : 'Wszystkie',
            slug: '',
            itemCount: totalItemCount,
        },
        ...categories.map((category) => ({
            ...category,
            itemCount: category[itemCountName],
        })),
    ];
}
