import React from 'react';
import { useLocation } from '@reach/router';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import {
    bar,
    active,
    link,
    name,
    count,
    dropdown,
    buttonBox,
    moreButton,
} from './categories-bar.module.scss';

import FlexBar from '../molecules/flex-bar';

const CategoriesBar = ({ categories, rootPath }) => {
    const { pathname } = useLocation();

    return (
        <FlexBar
            classes={{
                bar: bar,
                dropdown: dropdown,
                buttonBox: buttonBox,
                button: moreButton,
            }}
            activeItemIndex={getActiveCategoryIndex(categories, rootPath, pathname)}
            buttonContent="Pozostałe"
        >
            {categories.map((category, index) => {
                return (
                    <Link
                        className={link}
                        key={`category-link-${index}`}
                        to={`/${rootPath}/${category.slug ? `${category.slug}/` : ''}`}
                        activeClassName={active}
                    >
                        <span className={name}>
                            {category.name}
                            <span className={count}>{category.itemCount}</span>
                        </span>
                    </Link>
                );
            })}
        </FlexBar>
    );
};

function getActiveCategoryIndex(categories, rootPath, pathname) {
    return categories.findIndex(
        (category) => `/${rootPath}/${category.slug ? `${category.slug}/` : ''}` === pathname
    );
}

CategoriesBar.propTypes = {
    categories: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
            itemCount: PropTypes.number,
        })
    ).isRequired,
    rootPath: PropTypes.string.isRequired,
};

export default CategoriesBar;
