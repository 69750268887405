import mergeCategories from '../utills/merge-categories';

export default function useCategories(thisCategory, allCategories, options) {
    const { mergeCategoriesOptions, title, descriptionField } = options;

    const pageTitle = `${title} ${thisCategory ? ` | ${thisCategory.name}` : ''}`;
    const pageDescription = thisCategory?.[descriptionField];

    const categories = mergeCategories(allCategories, mergeCategoriesOptions);

    return {
        pageTitle,
        pageDescription,
        categories,
    };
}
