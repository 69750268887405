import React from 'react';

import { IConfirmationModalProps } from '../../../components/organisms/custom-modals/confirmation-modal';
import { addModalAction } from '../../actions/actions-modals';
import pointsWithText from '../../../utills/gamification/points-with-text';
import GamificationEmotics from '../../../assets/images/svg/gamification-emotics.svg';

const addPayByPointsCustomModalAction = (props: IConfirmationModalProps) => {
    return addModalAction({
        modalKey: 'CONFIRMATION_MODAL',
        modalProps: {
            Emotics: GamificationEmotics,
            buttonsSequence: 'reverse',
            ...props,
        },
    });
};

export const addLoginModalAction = (props: IConfirmationModalProps) => {
    return addPayByPointsCustomModalAction({
        title: 'Zaloguj się, aby skorzystać ze swoich Ketonów.',
        children: (
            <p>
                Jeśli masz wystarczającą ilość ketonów na swoim koncie,
                <br />
                możesz kupić ten produkt taniej.
            </p>
        ),
        confirmationText: 'Dodaj do koszyka',
        cancelText: 'Zaloguj się',
        ...props,
    });
};

interface IAddPayByPointsModalActionProps extends IConfirmationModalProps {
    pointsPercent: number;
}

export const addPayByPointsModalAction = ({
    pointsPercent,
    ...props
}: IAddPayByPointsModalActionProps) => {
    return addPayByPointsCustomModalAction({
        title: `Czy chcesz zapłacić ${pointsPercent}% ceny produktu ketonami?`,
        children: (
            <p>
                Jesteś bardzo aktywny w Strefie i należy Ci się nagroda!
                <br />
                Uzbierałeś odpowiednią ilość Ketonów,
                <br />
                by kupić ten produkt taniej!
            </p>
        ),
        confirmationText: 'Pewnie, że chcę!',
        cancelText: 'Może innym razem...',
        ...props,
    });
};

interface IAddNotEnoughPointsModalActionProps extends IConfirmationModalProps {
    pointsAmount: number;
}
// probably not used
export const addNotEnoughPointsModalAction = ({
    pointsAmount,
    ...props
}: IAddNotEnoughPointsModalActionProps) => {
    return addPayByPointsCustomModalAction({
        title: 'Za mało Ketonów by skorzystać z promocji.',
        children: (
            <p>
                Brakuje Ci jeszcze {pointsWithText(pointsAmount)}.
                <br />
                Nie martw się, możesz kupić ten produkt w regularnej cenie.
            </p>
        ),
        confirmationText: 'Dodaj do koszyka',
        cancelText: 'Wróć do sklepu',
        ...props,
    });
};
