// extracted by mini-css-extract-plugin
export var contentHolder = "product-card-module--content-holder--7Ufpc";
export var cover = "product-card-module--cover--ASN4E";
export var img = "product-card-module--img--Dz4Cb";
export var imgHolder = "product-card-module--img-holder--0q8kG";
export var pointsDiscountLabel = "product-card-module--points-discount-label--kzXyK";
export var productCardWrapper = "product-card-module--product-card-wrapper--HfYOH";
export var productLoader = "product-card-module--product-loader--CJQlH";
export var seeMoreButton = "product-card-module--see-more-button--izIra";
export var text = "product-card-module--text--yT0qW";
export var title = "product-card-module--title--JYjmx";