import React, { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';

import { pointsDiscountLabel, animatedKeton } from './points-discount-label.module.scss';
import { IPointsBenefitLabel } from '../../models/product-promotion.model';
import { selectUserData } from '../../redux/user/user.selectors';

import AnimatedKeton from './animated-keton';

export interface IPointsDiscountLabelProps {
    className?: string;
    labelPointsBenefit?: IPointsBenefitLabel;
}

export default function PointsDiscountLabel({
    children,
    className = '',
    labelPointsBenefit,
}: PropsWithChildren<IPointsDiscountLabelProps>) {
    const isUserLogged = useSelector(selectUserData) !== null;

    if (!isUserLogged || !labelPointsBenefit || Object.keys(labelPointsBenefit).length === 0) {
        return null;
    }

    const defaultText = `Zapłać ${labelPointsBenefit.pointsPercent}% ceny ketonami!`;

    return (
        <div className={`${pointsDiscountLabel} ${className}`}>
            <AnimatedKeton className={animatedKeton} />
            {children || defaultText}
        </div>
    );
}
