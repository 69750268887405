import _ from 'lodash';

export default function listItemToJSONLD(list, path) {
    return _.toArray(list).map(({ slug, name, title }, index) => ({
        '@type': 'ListItem',
        position: index,
        url: `${path}/${slug}`,
        name: name ? name : title ? title : undefined,
    }));
}
