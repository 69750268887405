import React, { useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import { getImage, withArtDirection } from 'gatsby-plugin-image';

import { list, wrapper, banner, navigation, promotionsLink } from './shop.module.scss';
import listItemToJSONLD from '../utills/list-item-to-json-ld';
import useCategories from '../hooks/use-categories';
import useProductDiscounts from '../hooks/use-product-discounts';

import SEO from '../components/seo';
import Main from '../layouts/main';
import MainBanner from '../components/organisms/main-banner';
import ProductCard from '../components/molecules/product-card/product-card';
import List from '../components/molecules/list/list';
import CategoriesBar from '../components/organisms/categories-bar.js';

const ShopPage = ({ data, pageContext }) => {
    const {
        shopBanner,
        shopBannerMobile,
        site,
        allProduct,
        allProductCategoryTree,
        productCategory,
    } = data;
    const { allProductCount } = pageContext;
    const { requestDiscountsAll } = useProductDiscounts();

    const { pageTitle, pageDescription, categories } = useCategories(
        productCategory,
        allProductCategoryTree.edges.map(({ node }) => node),
        {
            title: 'Sklep',
            descriptionField: 'shortDescription',
            mergeCategoriesOptions: {
                totalItemCount: allProductCount,
                itemCountName: 'productCount',
                firstCategoryName: 'Wszystkie produkty',
            },
        }
    );

    const bannerData = {
        fluidImg: withArtDirection(getImage(shopBanner), [
            {
                image: getImage(shopBannerMobile),
                media: `(max-width: 420px)`,
            },
        ]),
        title: 'Witaj w sklepie!',
        subtitle: 'Zobacz co dla Ciebie przygotowaliśmy',
    };

    const products = allProduct.edges.map(({ node }) => node);

    useEffect(() => {
        requestDiscountsAll();
    }, [requestDiscountsAll]);

    return (
        <>
            <SEO
                title={pageTitle}
                description={pageDescription}
                structuredData={{
                    '@type': 'ItemList',
                    name: `Sklep | ${site.siteMetadata.title}`,
                    itemListElement: listItemToJSONLD(products, `${site.siteMetadata.url}/sklep`),
                }}
            />
            <Main transparentHeader isPaddingEqualToHeaderOnPhone={true} className={wrapper}>
                <MainBanner
                    className={banner}
                    bannerData={bannerData}
                    alt={`Banner strony "Sklep"`}
                />
                {/* Link added on client request! */}
                <Link to="/sklep/promocje-6/" className={promotionsLink}>
                    Kliknij tu i skorzystaj teraz z aktualnych promocji na ten miesiąc w naszym
                    sklepie.&nbsp;😊🎁
                </Link>
                {categories.length > 1 && (
                    <div className={navigation}>
                        <CategoriesBar categories={categories} rootPath="sklep" />
                    </div>
                )}
                <List
                    items={products}
                    ItemComponent={ProductCard}
                    path="/sklep"
                    listClassName={list}
                />
            </Main>
        </>
    );
};

export const query = graphql`
    query($isMainShopPage: Boolean = false, $categoryId: Int, $productIds: [Int]) {
        shopBanner: file(relativePath: { eq: "shop-banner.png" }) {
            childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
            }
        }
        shopBannerMobile: file(relativePath: { eq: "shop-banner-mobile.png" }) {
            childImageSharp {
                gatsbyImageData(width: 500, quality: 100, layout: CONSTRAINED, placeholder: BLURRED)
            }
        }
        site {
            ...siteFields
        }
        productCategory(categoryId: { eq: $categoryId }) @skip(if: $isMainShopPage) {
            name
            shortDescription
            slug
        }
        allProductCategoryTree(
            sort: { fields: sequence, order: ASC }
            filter: { productCount: { gt: 0 } }
        ) {
            edges {
                node {
                    categoryId
                    name
                    slug
                    productCount
                }
            }
        }
        allProduct(
            filter: { productId: { in: $productIds } }
            sort: { fields: isPromoted, order: DESC }
        ) {
            edges {
                node {
                    ...productCardFields
                }
            }
        }
    }
`;

export default ShopPage;
